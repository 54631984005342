import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { API_URL, CAMPAIGN_ID, MEDIUM_ID } from '../../constants/constants';
import ResumenPagosFinal from '../../componentes/pagos/ResumenPagosFinal';
import DatosEnvio from '../../componentes/pagos/DatosEnvio';
import MetodoPago from '../../componentes/pagos/MetodoPago';
import PasoFacturacion from './PasoFacturaicon';
// import { selectedInvoice, selectedNoInvoice } from '../../utils/PixelMeta/metaPixelEvents';
import CargandoSpinner from '../../componentes/cargacomponetes/CargandoSpinner';
import { customRounding, roundToTwoDecimals } from '../../utils/utils';

function PaginaMetodoPAgo({ totalpago, totalenvio, datosPerfil, carts, direcciondeenvio, requierefactura, ocurre, mtdopago, office, plazoSeleccionado, losMsison, datosTempolares, totalmotores, totalEnvioCastores, totalCubreenvio, totalesDescuentos, dtoCupon }) {

    //-----NOTA: en caso de erorr impirmir en cosnola los datos que se reciben 
    const cartLocal = localStorage.getItem('cartLocal') ? JSON.parse(localStorage.getItem('cartLocal')) : null
    const navigate = useNavigate();
    //para evita el llamado doble a peidos peroe spero se arregle con el boton
    const [pedidoEnCurso, setPedidoEnCurso] = useState(false);
    const [isLoading, setIsLoading] = useState(false); // Estado para controlar la carga

    //Se obtiene le numero dle epdido
    const [numeropeidod, setNumeroPedido] = useState("");
    //facrtura 
    const [requiereFactura, setRequiereFactura] = useState(false);
    const [direccionFiscalDtos, setDireccionFiscalDtos] = useState([])

    const [formularioCompletado, setFormularioCompletado] = useState(false);

    // Contenido de tu página final
    const [nuevoMetodo, setNuevoMetodo] = useState(null);

    let totalDescuento = totalesDescuentos?.totaldescuento ?? totalpago;

    const carritoUsuario = totalesDescuentos?.productosConDescuento && totalesDescuentos.productosConDescuento.length > 0 ? totalesDescuentos.productosConDescuento : carts;

    //console.log('-------el carrito a usar es de  ', carritoUsuario)

    carritoUsuario.forEach((item, index) => {
        if (item.p_envio === 389 || item.p_envio === 510) {
            carritoUsuario[index] = {
                ...item,
                p_envio: item.p_envio + 1
            };
        }
    });

    // Calcular el total de envío para 'estafeta'
    const totalEnvioEstafeta = carritoUsuario
        .filter(item => item.t_envio === 'estafeta')
        .reduce((sum, item) => sum + (item.p_envio * item.cantidad), 0);

    //console.log('Total envío estafeta:', totalEnvioEstafeta);

    const tiposEnvio = [...new Set(carritoUsuario.map(item => item.t_envio))];

    //console.log('Diferentes tipos de envío:', tiposEnvio);

    let totalpedido;
    let subtotalmsi = totalDescuento + totalenvio;


    if (plazoSeleccionado && Object.keys(plazoSeleccionado).length !== 0) {
        totalpedido = plazoSeleccionado.precioFinal;

    } else {
        totalpedido = totalDescuento + totalenvio;
    }

    const saveLink = async (orderId, link) => {
        try {
            const config = {
                headers: {
                    'Authorization': datosPerfil['$tk3m09s']
                }
            };
            const formdata = {
                no_pedido: orderId,
                link_pago: link
            }

            const response = await axios.put(API_URL + '/updateOrder', formdata, config);
            return true
        } catch (error) {
            console.error('Ha ocurrido un error al obtener direcciones: ' + error.toString());
            return false
        }
    }

    //-*-*-*-*--------------------------------------------------------------------------------------------------------------------------------------------------------------------------
    const handlePayment = () => {
        setIsLoading(true)
        if (mtdopago) {
            // Lista de métodos permitidos
            const metodosPermitidos = ['oxxo', 'transferencia', 'credito', 'debito', 'mercadoPago'];

            // Verificar si el nuevo método está en la lista de métodos permitidos
            if (metodosPermitidos.includes(mtdopago)) {
                if (mtdopago === 'mercadoPago') {
                    payWithMercadoPago(mtdopago)
                } else {
                    // //console.log('El método seleccionado es válido, procediendo a ejecutar gopay');
                    gopay(mtdopago);
                }

            } else {
                // 'El método seleccionado no es válido, no se ejecutará gopay
            }
        }
    };

    const payWithMercadoPago = async (selectedMethod) => {
        const config = {
            headers: {
                'Authorization': datosPerfil['$tk3m09s']
            }
        };

        let carforplazos = carritoUsuario;
        let precioEnvioFinalPlazos = totalenvio;

        let total = parseFloat((totalDescuento + precioEnvioFinalPlazos).toFixed(2));
        let total_productos = totalDescuento;

        let order_data = {
            cliente: datosPerfil['$name'],
            email: datosPerfil['$email'],
            ocurre: ocurre,
            factura: requiereFactura,
            total_envio: precioEnvioFinalPlazos,

            //datos de totales de envio y lo que se cubre 
            gap: totalCubreenvio,
            envio_castores: parseFloat(totalEnvioCastores),

            total: totalpedido,
            total_productos: total_productos,
            msitiene: losMsison,
            ...(dtoCupon && { datos_cupon: dtoCupon })

        };

        const datosParaEnviar = {
            // sale_order_id: success, // NOTE: Se agrega despúes
            factura: requiereFactura,
            id_usuario: datosPerfil['$udi09345'],
            datos_orden: order_data,
            carrito_usuario: carforplazos,
            direccion_usuario: direcciondeenvio,
            metodo_pago: selectedMethod,
            office_id: String(office?.officeId),
            office_name: office?.officeName,
            direccion_fiscal: direccionFiscalDtos,
            cant_motores: totalmotores,
            tipo_envio: tiposEnvio,
            precio_envio: {
                castores: parseFloat(totalEnvioCastores),
                estafeta: parseFloat(totalEnvioEstafeta),
            }

        };
        // Crea el pedido en el sistema
        const idPedido = await newpedido(datosParaEnviar);
        // TODO: Cambiar por el id de pedido real
        // const idPedido = 610346232;
        if (!idPedido) {
            // No se pudo crear el pedido.
            return; // Salir de la función si no hay idPedido
        }

        // Crear preferencias de Mercado Pago
        const response = await axios.post(`${API_URL}/createPreferenceMP`, {
            products: carforplazos,
            orderId: idPedido,
            totalShipping: precioEnvioFinalPlazos,
            email: order_data?.email,
            address: {
                zip_code: direcciondeenvio?.cp
            }
        }, config);

        const data = response.data;
        if (data.id) {
            // Guarda link para pagar despues
            const response = await saveLink(idPedido, data.init_point);

            // Redirigir a la página de Mercado Pago
            window.location.href = data.init_point;
        }
    }

    const gopay = async (metodoSeleccionado) => {
        // ------------------------------------ INICIA PAGO ------------------------------------- //        

        // //console.log('El id de odoo al final es: ', success)

        const config = {
            headers: {
                'Authorization': datosPerfil['$tk3m09s']
            }
        };


        let carforplazos
        let precioEnvioFinalPlazos

        if (plazoSeleccionado && plazoSeleccionado.plazosProductosSinIva && plazoSeleccionado.plazosProductosSinIva.length > 0) {
            carforplazos = carritoUsuario.map(cartItem => {
                const plazoItem = plazoSeleccionado.plazosProductosSinIva.find(plazo => plazo.sku === cartItem.sku);
                if (plazoItem) {
                    return {
                        ...cartItem,
                        precio_sin_iva: {
                            ...cartItem.precio_sin_iva,
                            cantidad: plazoItem.precioFinalSIVA
                        }
                    };
                }
                return cartItem;
            });
            precioEnvioFinalPlazos = plazoSeleccionado.precioFinalEnvio;
        } else {
            carforplazos = carritoUsuario;
            precioEnvioFinalPlazos = totalenvio;
        }


        const { data } = await axios.get(API_URL + '/getPk', config)


        let total = parseFloat((totalDescuento + precioEnvioFinalPlazos).toFixed(2));
        let total_productos = totalDescuento;

        let order_data = {
            cliente: datosPerfil['$name'],
            email: datosPerfil['$email'],
            ocurre: ocurre,
            factura: requiereFactura,
            total_envio: precioEnvioFinalPlazos,

            //datos de totales de envio y lo que se cubre 
            gap: totalCubreenvio,
            envio_castores: parseFloat(totalEnvioCastores),

            total: totalpedido,
            total_productos: total_productos,
            msitiene: losMsison,
            ...(dtoCupon && { datos_cupon: dtoCupon })

        };

        // Si el método seleccionado es "credito", ajusta los valores y añade 'msi'
        if (metodoSeleccionado === 'credito') {
            // //console.log('El metodo es credito agregare ')
            total = plazoSeleccionado.precioFinal;  // Asume que plazoSeleccionado.precioFinal es un número válido
            total_productos = plazoSeleccionado.precioFinal - plazoSeleccionado.precioFinalEnvio;

            // Actualizar el total y total_productos en order_data
            order_data.total = parseFloat(total);
            order_data.total_productos = parseFloat(total_productos);

            order_data.mensualpay = parseFloat(plazoSeleccionado.precioMensual);  // Asume que plazoSeleccionado.meses es un número válido
            // Añadir 'msi' a order_data
            order_data.msi = parseInt(plazoSeleccionado.meses);  // Asume que plazoSeleccionado.meses es un número válido

            // //console.log('la data actulizada es ',order_data)
        }



        const datosParaEnviar = {
            // sale_order_id: success, // NOTE: Se agrega despúes
            factura: requiereFactura,
            id_usuario: datosPerfil['$udi09345'],
            datos_orden: order_data,
            carrito_usuario: carforplazos,
            direccion_usuario: direcciondeenvio,
            metodo_pago: metodoSeleccionado,
            pk: data?.success,
            office_id: String(office?.officeId),
            office_name: office?.officeName,
            direccion_fiscal: direccionFiscalDtos,
            cant_motores: totalmotores,
            tipo_envio: tiposEnvio,
            precio_envio: {
                castores: parseFloat(totalEnvioCastores),
                estafeta: parseFloat(totalEnvioEstafeta),
            }

        };

        //console.log('Datos a enviar', datosParaEnviar)

        // -------------------------------------- CREA ORDEN EN SISTEMA ------------------------------------- //

        const idPedido = await newpedido(datosParaEnviar); // Espera aquí

        if (!idPedido) {
            // No se pudo crear el pedido.
            return; // Salir de la función si no hay idPedido
        }

        //enviar los datos apra actulizar el inventairo una ves creadi el producto 
        cargarDatos()

        // ---------------------------------------- CREA ORDEN EN ODOO -------------------------------------- //

        // Llamar a ordentOdoo y esperas su respuesta
        let success = await ordentOdoo(idPedido);

        if (success == null) {
            success = await ordentOdoo(idPedido)
        }

        if (success == null) {
            console.error('No se genero la orden en odoo')
            success = "odoo"
        }

        // //console.log("Id de pedido de odoo: ", success)

        // -------------------------------------- ACTUALIZA ORDEN EN SISTEMA ---------------------------------- //

        // Actualiza el pedido con el id de odoo
        await axios.put(API_URL + '/createOrder', { order_id: idPedido, odoo_order_id: success.sale_order_id, odoo_order_name: success.sale_order_name }, config)

        datosParaEnviar['sale_order_id'] = success.sale_order_id

        // //console.log('Datos enviados a pagos: ', datosParaEnviar)


        // --------------------------------- ACTUALIZAR INVENTARIO DE COMBO --------------------------------- //
        const comboMap = new Map();

        // Llenar el Map con los valores únicos de id_combo y cantidad
        carts.forEach(producto => {
            if (!comboMap.has(producto.id_combo)) {
                comboMap.set(producto.id_combo, producto.cantidad);
            }
        });

        // Extraer los datos únicos y mostrarlos
        const resultadosUnicos = Array.from(comboMap).map(([id_combo, cantidad]) => ({ id_combo, cantidad }));

        // //console.log(resultadosUnicos);
        actualizarCombos(resultadosUnicos)
        actualizarCupon(dtoCupon.id_cupon, datosPerfil['$udi09345'])

        // ---------------------------- TERMINA DE ACTUALIZAR INVENTARIO DE COMBO --------------------------- //


        if (metodoSeleccionado === 'oxxo') {
            navigate(`/Pago-Oxxo/${idPedido}`, { state: { token: metodoSeleccionado, id: idPedido, datospadso: datosParaEnviar } });

        } else if (metodoSeleccionado === 'transferencia') {
            navigate(`/Pago-Tranferencia/${idPedido}`, { state: { token: metodoSeleccionado, id: idPedido, datospadso: datosParaEnviar } });
        } else {
            // Redirige a la ruta para agregar tarjeta con el token y el ID
            navigate('/pago-tarjeta', { state: { token: metodoSeleccionado, id: idPedido, datospadso: datosParaEnviar } });  //se envia el metodo(debito o credito) el numero de pedido y los datos del carrito para mostrar un resusmen opcional 
        }

        setIsLoading(false)


    }

    const actualizarCupon = async (id_cupo, id_user) => {

        const formdata = {
            coupon_id: id_cupo + "",
            user_id: id_user
        }
        const config = {
            headers: {
                'Authorization': datosPerfil['$tk3m09s']
            }
        };
        //console.log('los datas para actulizar son ', formdata)
        try {
            const response = await axios.put(API_URL + '/updateListUsersCoupon', formdata, config);
            //console.log('actulaizadno cupoon  ', response)

        } catch (error) {
            //console.log('ERROR', error)
        }

    }
    const ordentOdoo = async (sale_order_id) => {

        const valuesUTM = localStorage.getItem('utm') ? JSON.parse(localStorage.getItem('utm')) : null
        let isStoreval = datosPerfil['$r07sad'] === 'Tienda'; // Esto devuelve true si es 'Tienda', false en caso contrario

        //calcular el envio previo a que se calcule la comision
        let envioconcubre = 0

        //**********************************************inicio de calculos con estafeta******************************************************* */
        // Filtrar los productos con t_envio igual a estafeta y actualizarlos
        carritoUsuario.forEach((item, index) => {
            if (item.t_envio === 'estafeta') {
                const nuevoPrecio = item.precio.cantidad - item.p_envio;
                //console.log('Precio sin el envío y con IVA', nuevoPrecio);

                const precioSinIva = roundToTwoDecimals(nuevoPrecio / (1 + 0.16));
                //console.log('Nuevo precio sin IVA', precioSinIva);

                const descuentoDecimal = (item.descuento || 0) / 100;
                const precioSinIvaYDescuento = roundToTwoDecimals(precioSinIva / (1 - descuentoDecimal));
                //console.log('Nuevo precio sin IVA y sin descuento', precioSinIvaYDescuento);

                carritoUsuario[index] = {
                    ...item,
                    precio: {
                        ...item.precio,
                        cantidad: nuevoPrecio
                    },
                    precio_sin_iva: {
                        ...item.precio_sin_iva,
                        cantidad: precioSinIvaYDescuento
                    }
                };
            }
        });

        const envisinviaestafeta = totalEnvioEstafeta !== 0 ? roundToTwoDecimals(totalEnvioEstafeta / 1.16) : 0;
        //  //console.log('Envío sin IVA', envisinviaestafeta);


        //console.log('-------------Carrito de usuario actualizado:', carritoUsuario);
        //**********************************************inicio de calculos con castores******************************************************* */
        //calcular si el pedido tien cubre envio o no 
        if (totalCubreenvio && totalCubreenvio !== 0) {
            totalEnvioCastores = parseFloat(totalEnvioCastores);

            //console.log('Los totales del envío son', totalEnvioCastores, totalCubreenvio);

            //console.log('************************El carrito para trabajar es de:', carritoUsuario);

            if (totalCubreenvio > totalEnvioCastores) {

                //console.log('Voy a usar el totalEnvioCastores', totalEnvioCastores);

                // Encontrar el elemento con el valor cubre_envio más alto en carritoUsuario
                let itemConCubreEnvioMasAlto = carritoUsuario.reduce((maxItem, currentItem) => {
                    return (currentItem.cubre_envio > maxItem.cubre_envio) ? currentItem : maxItem;
                });

                //console.log('El elemento con cubre_envio más alto es:', itemConCubreEnvioMasAlto);


                const nvototal = roundToTwoDecimals(itemConCubreEnvioMasAlto.precio.cantidad - totalEnvioCastores);
                //console.log('Precio sin el envío y con IVA', nvototal);

                const presiiosiniva1 = roundToTwoDecimals(nvototal / (1 + 0.16));
                //console.log('Nuevo precio sin IVA', presiiosiniva1);

                const descecimal = itemConCubreEnvioMasAlto.descuento / 100

                const preciosindescuentoiva = roundToTwoDecimals(presiiosiniva1 / (1 - descecimal));
                //console.log('Nuevo precio sin IVA y sin descuento', preciosindescuentoiva);

                const precioSinIvaConDescuento = roundToTwoDecimals(preciosindescuentoiva);
                const envisinvia = roundToTwoDecimals(totalEnvioCastores / 1.16);
                //console.log('Dato1', precioSinIvaConDescuento);
                //console.log('Envío sin IVA', envisinvia);
                envioconcubre = roundToTwoDecimals(totalEnvioCastores)
                // Actualizar el itemConCubreEnvioMasAlto con los nuevos valores
                itemConCubreEnvioMasAlto.precio.cantidad = nvototal;
                itemConCubreEnvioMasAlto.precio_sin_iva.cantidad = precioSinIvaConDescuento;

                // Encontrar el índice del elemento a actualizar en carritoUsuario
                const index = carritoUsuario.findIndex(item => item.id_producto === itemConCubreEnvioMasAlto.id_producto);

                if (index !== -1) {
                    carritoUsuario[index].precio.cantidad = nvototal;
                    carritoUsuario[index].precio_sin_iva.cantidad = precioSinIvaConDescuento;
                }
                //console.log('Carrito actualizado:', carritoUsuario);

            } else {
                //console.log('Voy a usar el totalCubreenvio');

                // Encontrar el elemento con el valor cubre_envio más alto en carritoUsuario
                let itemConCubreEnvioMasAlto = carritoUsuario.reduce((maxItem, currentItem) => {
                    return (currentItem.cubre_envio > maxItem.cubre_envio) ? currentItem : maxItem;
                });

                //console.log('El elemento con cubre_envio más alto es:', itemConCubreEnvioMasAlto);

                const nvototal = itemConCubreEnvioMasAlto.precio.cantidad - totalCubreenvio;
                //console.log('Precio sin el envío y con IVA', nvototal);

                const presiiosiniva1 = roundToTwoDecimals(nvototal / (1 + 0.16));
                //console.log('Nuevo precio sin IVA', presiiosiniva1);

                const desceuntodecimal = itemConCubreEnvioMasAlto.descuento / 100
                //console.log('descuentodecimal es de   ', desceuntodecimal)


                const preciosindescuentoiva = roundToTwoDecimals(presiiosiniva1 / (1 - desceuntodecimal));
                //console.log('Nuevo precio sin IVA y sin descuento', preciosindescuentoiva);

                const precioSinIvaConDescuento = roundToTwoDecimals(preciosindescuentoiva);

                const envisinvia = roundToTwoDecimals(totalEnvioCastores / 1.16);
                //console.log('Dato1', precioSinIvaConDescuento);
                //console.log('Envío sin IVA', envisinvia);
                envioconcubre = roundToTwoDecimals(totalEnvioCastores)
                // Actualizar el itemConCubreEnvioMasAlto con los nuevos valores
                itemConCubreEnvioMasAlto.precio.cantidad = nvototal;
                itemConCubreEnvioMasAlto.precio_sin_iva.cantidad = precioSinIvaConDescuento;

                // Encontrar el índice del elemento a actualizar en carritoUsuario
                const index = carritoUsuario.findIndex(item => item.id_producto === itemConCubreEnvioMasAlto.id_producto);

                if (index !== -1) {
                    carritoUsuario[index].precio.cantidad = nvototal;
                    carritoUsuario[index].precio_sin_iva.cantidad = precioSinIvaConDescuento;
                }

                //console.log('Carrito actualizado:', carritoUsuario);

            }
        }
        //vasriable sqeu usarmeos para el envio de datos a odo 
        let carforOdood;
        let precioEnvioFinal;
        let totalP2 = 0;
        let totalcomision

        //**********************************************inicio de calculos con la comision en el pedido y el pago******************************************************* */

        //operaicones de quitar la comiauon con las dos opcibnes pirmara tarjeta credito segunda tarjeta devito 
        if (plazoSeleccionado?.meses !== "1" && plazoSeleccionado?.plazosProductosSinIva && plazoSeleccionado.plazosProductosSinIva.length > 0) {

            //caluart la comision en dado caso de que el apgo sea a meses  financiadoas 
            //calcular la doiferencia de la comsion 
            const comisioniva = roundToTwoDecimals(totalpedido - subtotalmsi)
            // calcular el iva d ela comision
            const comisiofinal = roundToTwoDecimals(comisioniva / 1.16)
            //asignmaos el carrito que enviaremos al de odo 
            carforOdood = carritoUsuario;

            // obtneemos el coso del encio 
            let envioBase = envioconcubre !== 0 ? envioconcubre : totalenvio;

            let enviosinvia = 0
            /// en caso de que el envio de estafeta sea diferente de 0 se entr a ala funcion apra agregar a los enficos 
            if (envisinviaestafeta !== 0) {

                const enviobasesiniva = roundToTwoDecimals(envioBase / 1.16)

                enviosinvia = enviobasesiniva + envisinviaestafeta

            } else {
                //console.log('pdatos en estafeta a 0');

                enviosinvia = roundToTwoDecimals(envioBase / 1.16)

                //console.log('el envio sin iva es de ', enviosinvia)

            }

            totalcomision = comisiofinal
            precioEnvioFinal = enviosinvia;
        } else if (mtdopago === 'debito' || (plazoSeleccionado?.meses === "1" || mtdopago === 'creditocontado')) {

            //datoa para fines de la comision al banco*-*-*-*-*-*-*-*-*-*-*****************************************-

            //console.log('/*-/*-/*-/*-/*/*/*/-------------------------------------------/*/*/*/*/*/*')
            carforOdood = carritoUsuario.map(cartItem => {
                // Convertir el descuento a decimal
                let descuentoDecimal = cartItem.descuento / 100;

                // Realizar los cálculos
                let p1 = customRounding(cartItem.precio_sin_iva.cantidad * (1 - descuentoDecimal));
                //console.log('el precio con descuento es ', p1)

                let p2 = customRounding((p1 / (1 + 0.013)));
                //console.log('el precio sin comision es  ', p2)

                let p3 = customRounding((p1 - p2));
                //console.log('la comisione es ', p3)
                let pnvosiniva = customRounding(p2 / (1 - descuentoDecimal));
                //console.log('el precio sin iva es ', pnvosiniva)
                // Sumar el valor de p2 al total

                const comifnal = customRounding(p3 * cartItem.cantidad)
                //console.log('el total de la comsiosion esd e ', comifnal)

                totalP2 += customRounding(comifnal);

                totalcomision = customRounding(totalP2)

                //console.log('la comisiones ', totalcomision)
                //console.log('*----------------------------------------------------------------------------*')
                // Actualizar el valor de precio_sin_iva.cantidad
                return {
                    ...cartItem,

                    precio_sin_iva: {
                        ...cartItem.precio_sin_iva,
                        cantidad: pnvosiniva
                    }
                };
            });


            // calculos par ale envio sin iva 
            const envioBase = envioconcubre !== 0 ? envioconcubre : totalenvio;
            //console.log('envio inicial ', envioBase)

            const enviosiniva = customRounding(envioBase / 1.16)
            //console.log('el envio sin iva es', enviosiniva)
            const comisionenvio = customRounding(enviosiniva / 1.013)

            //console.log('el envio si comisioes esde ', comisionenvio)


            //calculo del envio sin iva 
            const envccsiva = customRounding(enviosiniva - comisionenvio)
            totalcomision = customRounding(totalcomision + envccsiva)
            //console.log('la nueva comisieon con el envio se de ', totalcomision)
            //console.log('el envio sin iva y comisicon es es', comisionenvio)

            const comisionenvioestafeta = envisinviaestafeta !== 0 ? customRounding(envisinviaestafeta / 1.013) : 0;

            //funciones par aagregar el envio de estafeta en caso de que el envio sea diferente de 0
            if (comisionenvioestafeta !== 0) {
                //console.log('--Estoy usando el envio sin iva de ', envisinviaestafeta)
                //console.log('--Precio envio sin comision estafeta:', comisionenvioestafeta);
                const envccsivaestafeta = customRounding(envisinviaestafeta - comisionenvioestafeta);

                //console.log('La comisión de estafeta es de:', envccsivaestafeta);
                totalcomision += envccsivaestafeta;
                //console.log('La nueva comisión con el envío con estafeta es de:', totalcomision);
                //console.log('El envío sin IVA y comisión de estafeta es de:', comisionenvioestafeta);

                precioEnvioFinal = comisionenvio + comisionenvioestafeta


            } else {
                //console.log('pdatos en estafeta a 0');
                precioEnvioFinal = comisionenvio;
            }

            const enviocomision = customRounding(comisionenvio * 1.16)
        } else if (mtdopago === 'oxxo') {

            //datoa para fines de la comision al banco*-*-*-*-*-*-*-*-*-*-*****************************************-

            //console.log('/*-/*-/*-/*-/*/*/*/-------------------------------------------/*/*/*/*/*/*')
            carforOdood = carritoUsuario.map(cartItem => {
                // Convertir el descuento a decimal
                let descuentoDecimal = cartItem.descuento / 100;

                // Realizar los cálculos
                let p1 = customRounding(cartItem.precio_sin_iva.cantidad * (1 - descuentoDecimal));
                //console.log('el precio con descuento es ', p1)

                let p2 = customRounding((p1 / (1 + 0.036)) + 3); // Calcular la comisión y sumar 3 pesos
                //console.log('el precio sin comision es  ', p2)

                let p3 = customRounding((p1 - p2));
                //console.log('la comisione es ', p3)

                let pnvosiniva = customRounding(p2 / (1 - descuentoDecimal));
                //console.log('el precio sin iva es ', pnvosiniva)
                // Sumar el valor de p2 al total

                const comifnal = customRounding(p3 * cartItem.cantidad)
                //console.log('el total de la comsiosion esd e ', comifnal)

                totalP2 += customRounding(comifnal);

                totalcomision = customRounding(totalP2)

                //console.log('la comisiones ', totalcomision)
                //console.log('*----------------------------------------------------------------------------*')
                // Actualizar el valor de precio_sin_iva.cantidad
                return {
                    ...cartItem,

                    precio_sin_iva: {
                        ...cartItem.precio_sin_iva,
                        cantidad: pnvosiniva
                    }
                };
            });


            // calculos par ale envio sin iva 
            const envioBase = envioconcubre !== 0 ? envioconcubre : totalenvio;
            //console.log('envio inicial ', envioBase)

            const enviosiniva = customRounding(envioBase / 1.16)
            //console.log('el envio sin iva es', enviosiniva)
            const comisionenvio = customRounding((enviosiniva / 1.036) + 3); // Calcular la comisión y sumar 3 pesos

            //console.log('el envio si comisioes esde ', comisionenvio)
            //calculo del envio sin iva 
            const envccsiva = customRounding(enviosiniva - comisionenvio)
            totalcomision = customRounding(totalcomision + envccsiva)
            //console.log('la nueva comisieon con el envio se de ', totalcomision)
            //console.log('el envio sin iva y comisicon es es', comisionenvio)

            const comisionenvioestafeta = envisinviaestafeta !== 0 ? customRounding((envisinviaestafeta / 1.036) + 3) : 0;

            //funciones par aagregar el envio de estafeta en caso de que el envio sea diferente de 0
            if (comisionenvioestafeta !== 0) {
                //console.log('--Estoy usando el envio sin iva de ', envisinviaestafeta)
                //console.log('--Precio envio sin comision estafeta:', comisionenvioestafeta);
                const envccsivaestafeta = customRounding(envisinviaestafeta - comisionenvioestafeta);

                //console.log('La comisión de estafeta es de:', envccsivaestafeta);
                totalcomision += envccsivaestafeta;
                //console.log('La nueva comisión con el envío con estafeta es de:', totalcomision);
                //console.log('El envío sin IVA y comisión de estafeta es de:', comisionenvioestafeta);

                precioEnvioFinal = comisionenvio + comisionenvioestafeta


            } else {
                //console.log('pdatos en estafeta a 0');
                precioEnvioFinal = comisionenvio;
            }

            const enviocomision = customRounding(comisionenvio * 1.16)
        } else if (mtdopago === 'transferencia') {

            //datoa para fines de la comision al banco*-*-*-*-*-*-*-*-*-*-*****************************************

            //console.log('/*-/*-/*-/*-/*/*/*/-------------------------------------------/*/*/*/*/*/*')
            carforOdood = carritoUsuario.map(cartItem => {
                // Convertir el descuento a decimal
                let descuentoDecimal = cartItem.descuento / 100;

                // Realizar los cálculos
                let p1 = customRounding(cartItem.precio_sin_iva.cantidad * (1 - descuentoDecimal));
                //  console.log('el precio con descuento es ', p1)

                let p2 = customRounding((p1 - 7)); // Calcular la comisión y sumar 3 pesos

                //console.log('el precio sin comision es  ', p2)
                let p3 = 7;

                //console.log('la comisione es ', p3)

                let pnvosiniva = customRounding(p2 / (1 - descuentoDecimal));
                //console.log('el precio sin iva es ', pnvosiniva)
                // Sumar el valor de p2 al total

                const comifnal = p3
                //console.log('el total de la comsiosion esd e ', comifnal)



                totalcomision = 7

                //console.log('la comisiones ', totalcomision)
                //console.log('*----------------------------------------------------------------------------*')
                // Actualizar el valor de precio_sin_iva.cantidad
                return {
                    ...cartItem,

                    precio_sin_iva: {
                        ...cartItem.precio_sin_iva,
                        cantidad: pnvosiniva
                    }
                };
            });


            // calculos par ale envio sin iva 
            const envioBase = envioconcubre !== 0 ? envioconcubre : totalenvio;
            //console.log('envio inicial ', envioBase)

            const enviosiniva = customRounding(envioBase / 1.16)
            //console.log('el envio sin iva es', enviosiniva)
            const comisionenvio = customRounding(enviosiniva); // Calcular la comisión y sumar 3 pesos

            //console.log('el envio si comisioes esde ', comisionenvio)


            //calculo del envio sin iva 
            const envccsiva = customRounding(comisionenvio)
            totalcomision = customRounding(totalcomision)
            //console.log('la nueva comisieon con el envio se de ', totalcomision)
            //console.log('el envio sin iva y comisicon es es', comisionenvio)

            const comisionenvioestafeta = envisinviaestafeta !== 0 ? customRounding(envisinviaestafeta) : 0;

            //funciones par aagregar el envio de estafeta en caso de que el envio sea diferente de 0
            if (comisionenvioestafeta !== 0) {

                precioEnvioFinal = comisionenvio + comisionenvioestafeta
                //console.log('1----', precioEnvioFinal)

            } else {
                //console.log('pdatos en estafeta a 0');
                precioEnvioFinal = comisionenvio;
                //console.log('2----', precioEnvioFinal)

            }

            const enviocomision = customRounding(comisionenvio * 1.16)
        }



        else {

            // en caos que no cumpla conn los datos se manda normla el carrito 
            carforOdood = carritoUsuario;
            precioEnvioFinal = totalenvio;

        }

        // Sección para verificar los cálculos y ajusta rap´ra la factura de odo 
        //console.log('Verificación de cálculos:');
        let totalsiniva = 0;
        let totaliva = 0;
        //**********************************************inicio de calculos de verificaicon con el carro quei se enviara a odoo******************************************************* */

        carforOdood.forEach(cartItem => {
            let descuentoDecimal = cartItem.descuento / 100;
            let precioSinIva = customRounding(cartItem.precio_sin_iva.cantidad);

            // Calcular el total sin IVA para cada producto
            let totalsinivaProducto = roundToTwoDecimals(precioSinIva * (1 - descuentoDecimal));
            // Calcular el IVA para cada producto
            let ivaProducto = customRounding(totalsinivaProducto * 0.16)

            // console.log(`Producto ID: ${cartItem.id_producto}, Precio sin IVA: ${precioSinIva}, Descuento: ${descuentoDecimal * 100}%, Total sin IVA: ${totalsinivaProducto}, IVA: ${ivaProducto}`);

            // Acumular los totales
            totalsiniva += totalsinivaProducto;
            totaliva += ivaProducto;
        });

        let ivacomision = roundToTwoDecimals((totalcomision * 1.16) - totalcomision);
        let ivaenvio = roundToTwoDecimals((precioEnvioFinal * 1.16) - precioEnvioFinal);

        // Acumular los IVAs
        totaliva += customRounding(ivacomision + ivaenvio);

        totaliva = customRounding(totaliva); // Redondear el valor de totaliva

        // Calcular el total sin IVA
        totalsiniva += customRounding(totalcomision + precioEnvioFinal);
        totalsiniva = roundToTwoDecimals(totalsiniva)
        // Calcular el total final Odoo
        let totalFinalOdoo = roundToTwoDecimals(totalsiniva + totaliva);

        //console.log(`Total Comisión: ${totalcomision}`);
        //console.log(`IVA Comisión: ${ivacomision}`);
        //console.log(`Precio Envío Final: ${precioEnvioFinal}`);
        //console.log(`IVA Envío: ${ivaenvio}`);
        //console.log('----------------------------------------TICKET----------------------------------------')
        //console.log(`Total sin IVA: ${totalsiniva}`);
        //console.log(`Total IVA: ${totaliva}`);
        //console.log(`Total Final Odoo: ${totalFinalOdoo}`);


        // Comparar totalFinalOdoo con totalpedido
        if (totalFinalOdoo !== totalpedido) {
            let diferencia = totalFinalOdoo - totalpedido;
            diferencia = customRounding(diferencia)

            if (totalFinalOdoo > totalpedido) {
                totalcomision -= diferencia; // Restar diferencia si totalFinalOdoo es mayor
                totalcomision = customRounding(totalcomision)
                //console.log(`Diferencia: ${diferencia}. Total Comisión ajustada: ${totalcomision}`);
            } else {
                totalcomision += Math.abs(diferencia); // Sumar diferencia si totalFinalOdoo es menor
                totalcomision = customRounding(totalcomision)
                //console.log(`Diferencia: ${Math.abs(diferencia)}. Total Comisión ajustada: ${totalcomision}`);
            }
        } else {
            //console.log('Total Final Odoo y Total Pedido son iguales.');
        }



        let formdata = {
            sale_order_id: sale_order_id,
            odoo_id: datosPerfil['$od'],
            id_cliente: datosPerfil?.['$udi09345'] || '',
            productos: carforOdood,
            precio_envio: precioEnvioFinal,
            precio_comision: totalcomision,


            IsStore: isStoreval, // Uso la variable 'isStore' que establecimos arriba

            team_id: 11,
            // origin: "Website",
            // campaign_id: 9,
            // medium_id: 19,
        }

        if (isStoreval) {
            formdata.rol = "Tienda"
        }

        // Agregar datos de 'datosTempolares' solo si están disponibles
        if (datosTempolares && Object.keys(datosTempolares).length > 0) {
            formdata.partner_shipping_id = datosTempolares.partner_shipping_id;
            formdata.temporal_address = datosTempolares.temporal_address;
        }

        if (valuesUTM) {
            formdata['origin'] = valuesUTM?.utmSource;
            formdata['medium_id'] = MEDIUM_ID.MESSENGER;
            formdata['campaign_id'] = CAMPAIGN_ID.MARZO_2024;

        }
        //console.log('generare la orden oddo con ', formdata)
        try {

            const config = {
                headers: {
                    'Authorization': datosPerfil['$tk3m09s']
                }
            };

            const response = await axios.post(API_URL + '/odoo/create_sale_order', formdata, config);
            // //console.log('la orden de odoo es ', response.data)
            return { "sale_order_id": response.data.sale_order_id, "sale_order_name": response.data.sale_order_name }

            // Realizar acciones después del pedido si es necesario
        } catch (error) {
            console.error("Error al realizar la petición a la API:", error);
            return null; // Retorna null o maneja el error adecuadamente

        }
    }


    // ---------------------------------- FUNCIÓN PARA CREAR ----------------------------------- //
    // ------------------------------- UN PEDIDO EN EL SISTEMA -------------------------------- //
    const newpedido = async (datos) => {
        if (pedidoEnCurso) {
            // Ya hay un pedido en curso.
            return null; // Retorna null o alguna señal de que no se creó un nuevo pedido
        }

        setPedidoEnCurso(true);
        try {
            //console.log('los fdato son', datos)
            const response = await axios.post(API_URL + '/createOrder', datos);
            setNumeroPedido(response.data.status)
            return response.data.status; // Retorna el valor de status

            // Realizar acciones después del pedido si es necesario
        } catch (error) {
            console.error("Error al realizar la petición a la API:", error);
            return null; // Retorna null o maneja el error adecuadamente

        } finally {
            setPedidoEnCurso(false);
        }
    };

    //datos para obrtener los nume3ros de inventario de producto

    const cargarDatos = async () => {

        const skuYCantidades = carts.map(item => ({
            idproductocar: item.id_producto,
            cantidad: item.cantidad
        }));

        for (const item of skuYCantidades) {
            const payload = { "id_producto": parseInt(item.idproductocar) };

            try {
                const respuesta = await axios.post(API_URL + '/getProductById', payload, { timeout: 10000 });

                const datosRecibidos = respuesta.data[0];
                if (datosRecibidos && datosRecibidos.stock && typeof datosRecibidos.stock.disponible !== 'undefined') {
                    // Aquí realizas la operación con la cantidad

                    const nuevoDisponible = datosRecibidos.stock.disponible - item.cantidad;

                    actualizarInventario(item.idproductocar, nuevoDisponible)

                } else {
                    //No se encontraron datos de stock para el producto
                }

            } catch (error) {
                console.error('Error al cargar datos para el producto con ID:', item.idproductocar, error);
                if (error.code === 'ECONNABORTED') {
                    //console.log('Request timed out getProductById');
                }
            }
        }


    };


    // ----------------------------------- FUNCIÓN PARA ACTUALIZAR -------------------------------------- //
    // ------------------------------- UN PEDIDO INVENTARIO DE UN COMBO -------------------------------- //

    const actualizarCombos = async (listacomboscatulizar) => {
        // //console.log('Actulizare la lsita ', listacomboscatulizar)

        for (const item of listacomboscatulizar) {
            const formdata = { "combo_id": parseInt(item.id_combo) };
            // //console.log('El com es ', formdata)
            try {
                const response = await axios.post(API_URL + '/getCombos', formdata);
                // //console.log('Los Datos del combo elegido son padrino ', response.data.success.stock.disponible)
                const datosRecibidos = response.data.success.stock.disponible

                const nuwtotal = datosRecibidos - item.cantidad
                // //console.log('enviare a actulizar n vantidad ', nuwtotal, item.id_combo)
                actualizarInventarioCombo(item.id_combo, nuwtotal)
            } catch (error) {
                //console.log('ERROR', error)
            }

        }

    }

    const actualizarInventarioCombo = async (idCombo, stockDisponible) => {

        const formData = {

            "id_combo": parseInt(idCombo),
            'stock_disponible': stockDisponible
        };

        try {

            const config = {
                headers: {
                    'Authorization': datosPerfil['$tk3m09s']
                }
            };

            const respuesta = await axios.post(API_URL + '/updateStockCombos', formData, config);
            // //console.log(respuesta)

        } catch (error) {
            console.error('Error en actualizarInventario:', error);
        }
    };
    // -------------------------------------------------------------------------------------------- //
    // ----------------------------------- FIN DE FUNCIONES -------------------------------------- //
    // ------------------------------------------------------------------------------------------ //


    //Funcion que actualiza el inventario 

    const actualizarInventario = async (idProducto, stockDisponible) => {

        const formData = {

            "id_producto": parseInt(idProducto),
            'stock_disponible': stockDisponible
        };

        try {

            const config = {
                headers: {
                    'Authorization': datosPerfil['$tk3m09s']
                }
            };

            const respuesta = await axios.post(API_URL + '/updateStockProduct', formData, config);

        } catch (error) {
            console.error('Error en actualizarInventario:', error);
        }
    };



    //facturaicon

    const manejarDatosFormulario = (datos, formulario) => {

        // //console.log("Requiere factura: ", datos);
        ////console.log('tiene datos de facturacion del formulario', formulario)
        // -------- Pixel Meta ---------- //
        if (datos.requiereFactura) {
            // El usuario requiere factura
            // selectedInvoice(datosPerfil ? datosPerfil['$email'] : 'No registrado')
        } else {
            // El usuario no requiere factura
            // selectedNoInvoice(datosPerfil ? datosPerfil['$email'] : 'No registrado')
        }

        setRequiereFactura(datos.requiereFactura)
        setDireccionFiscalDtos(formulario)
        // Aquí estableces el estado para indicar que el formulario se ha completado
        setFormularioCompletado(true);
    };


    let totalpedidoComponente;

    //funciones para mostrar los plazos 
    if (plazoSeleccionado && Object.keys(plazoSeleccionado).length !== 0) {

        const totalpedidoamsi = totalDescuento + totalenvio;

        totalpedidoComponente = (
            <div>

                <div className="flex items-center justify-between w-80 mx-auto lg:hidden ">
                    <p className="text-[#757575] text-sm mr-2">Productos:</p>
                    <h2 className="text-[#012a45] text-sm">${totalDescuento?.toLocaleString('es-MX', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</h2>
                </div>
                <div className="flex items-center mt-2 justify-between w-80 mx-auto mt-1 lg:hidden">
                    <p className="text-[#757575] text-sm mr-2">Envio:</p>
                    <h2 className="text-[#030303] text-sm">
                        {totalenvio > 0 ?
                            `$${totalenvio.toLocaleString('es-MX', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}` :
                            'Envío Gratis'
                        }
                    </h2>
                </div>
                <div className="flex items-center mt-2 justify-between w-80 mx-auto pb-2 border-b-2 bordergray-200 lg:hidden">
                    <p className="text-[#757575] text-sm mr-2">Subtotal:</p>
                    <h2 className="text-[#012a45] text-sm">${totalpedidoamsi?.toLocaleString('es-MX', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</h2>

                </div>


                <div className="flex items-center justify-between w-80 mt-2 mx-auto lg:hidden">
                    <p className="text-[#757575] text-sm mr-2">Pagas</p>
                    <h2 className="text-[#012a45] text-base">
                        {plazoSeleccionado.meses}x ${plazoSeleccionado.precioMensual?.toLocaleString('es-MX', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                    </h2>
                </div>

            </div>
        );
    } else {
        totalpedidoComponente = (
            <div>
                <div className="flex items-center justify-between w-80 mx-auto lg:hidden ">
                    <p className="text-[#757575] text-sm mr-2">Productos:</p>
                    <h2 className="text-[#012a45] text-sm">${totalDescuento?.toLocaleString('es-MX', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</h2>
                </div>
                <div className="flex items-center justify-between w-80 mx-auto mt-1 lg:hidden">
                    <p className="text-[#757575] text-sm mr-2">Envio:</p>
                    <h2 className="text-[#030303] text-sm">
                        {totalenvio > 0 ?
                            `$${totalenvio.toLocaleString('es-MX', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}` :
                            'Envío Gratis'
                        }
                    </h2>
                </div>
            </div>
        );
    }
    return (
        <div className='px-4 py-2 flex flex-col min-h-screen '> {/* Establece una altura mínima para toda la pantalla y organiza los elementos en columna */}

            <p className='text-center text-[#1e3d8b] font-semibold text-2xl font-montserrat'>
                CONFIRMA TU COMPRA
            </p>
            {isLoading ? (
                <CargandoSpinner txtcharge="Cargando datos" />
            ) : (
                <div>
                    <div className='flex-grow border border-gray-200 lg:justify-between lg:flex mb-24'> {/* El contenido crece para ocupar el espacio disponible */}
                        <div>
                            <ResumenPagosFinal listaCar={carritoUsuario.length > 0 ? carritoUsuario : cartLocal} />

                            <div className="hidden lg:flex lg:flex-col lg:items-end px-8 lg:pr-24">
                                <div className="flex justify-between w-full pb-1 border-b-2 border-gray-300">
                                    <p className="text-[#030303] font-black text-base mr-2">
                                        Total de pedido
                                    </p>
                                    <h2 className="text-[#012a45] text-sm">
                                    </h2>
                                </div>


                                {dtoCupon && Object.keys(dtoCupon).length > 0 && (
                                    <div className="flex justify-between w-full mt-2">
                                        <p className="text-green-600 text-md mr-2">Descuento <span className='font-semibold'>{dtoCupon?.nombre}</span>:</p>
                                        <h2 className="text-green-600 text-base font-semibold">
                                            {dtoCupon?.des_cantidad !== 0
                                                ? `$${dtoCupon.des_cantidad}`
                                                : `${dtoCupon?.des_porcentaje}%`
                                            }
                                        </h2>
                                    </div>
                                )}


                                <div className="flex justify-between w-full mt-2">
                                    <p className="text-[#757575] text-base mr-2">Total de productos</p>
                                    <h2 className="text-[#012a45] text-base">
                                        ${totalDescuento?.toLocaleString('es-MX', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                                    </h2>
                                </div>

                                <div className="flex items-center justify-between w-full mt-1">
                                    <p className="text-[#757575] text-base mr-2">Costo de envío</p>
                                    <h2 className="text-[#030303] text-base">
                                        {
                                            totalenvio > 0 ?
                                                `$${totalenvio.toLocaleString('es-MX', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}` :
                                                'Envío Gratis'
                                        }
                                    </h2>
                                </div>

                                {plazoSeleccionado && Object.keys(plazoSeleccionado).length !== 0 ? (
                                    <>
                                        <div className="flex items-center justify-between w-full mt-2">
                                            {/* Contenido adicional que deseas mostrar cuando plazoSeleccionado está presente */}
                                            <p className="text-[#757575] text-base mr-2">Subtotal</p>
                                            <h2 className="text-[#030303] text-base">
                                                $ {subtotalmsi?.toLocaleString('es-MX', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                                            </h2>
                                        </div>





                                        <div className="flex items-center border-y-2 py-2 border-gray-200 justify-between w-full mt-2">
                                            {/* Contenido adicional que deseas mostrar cuando plazoSeleccionado está presente */}
                                            <p className="text-[#757575] text-base mr-2">Pagas</p>
                                            <h2 className="text-[#030303] text-base">

                                                {plazoSeleccionado.meses}x ${plazoSeleccionado.precioMensual?.toLocaleString('es-MX', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}

                                            </h2>
                                        </div>

                                        <div className="flex items-center justify-between w-full mt-2">
                                            {/* Contenido adicional que deseas mostrar cuando plazoSeleccionado está presente */}
                                            <p className="text-[#474747] text-base mr-2 font-semibold">Total</p>
                                            <h2 className="text-black text-lg font-semibold">

                                                $ {totalpedido?.toLocaleString('es-MX', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}

                                            </h2>
                                        </div>



                                    </>

                                ) : (
                                    <div className="flex items-center justify-between w-full mt-2">
                                        <p className="text-[#474747] text-base mr-2 font-semibold">Total a pagar</p>
                                        <h2 className="text-black text-lg font-semibold">
                                            $ {totalpedido?.toLocaleString('es-MX', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                                        </h2>
                                    </div>
                                )}
                            </div>



                        </div>
                        <div>

                            <div className='mt-6 px-1 text-[#030303] font-semibold text-xl lg:hidden border-b-2 border=gray-200 w-4/5 ml-5'>
                                <h2>
                                    Detalles de tu compra
                                </h2>
                            </div>
                            {/* Utilizando el componente DatosEnvio */}
                            <DatosEnvio direcciondeenvio={direcciondeenvio} />

                            {/* Utilizando el componente MetodoPago */}
                            <MetodoPago mtdopago={mtdopago} title='Método de pago' />

                            <PasoFacturacion datosPerfil={datosPerfil} onEnviarDatos={manejarDatosFormulario} />
                            <div className='mb-10 h-40'>

                            </div>
                        </div>



                    </div>
                    <div className="flex flex-col justify-center lg:w-4/5 bg-white border-t-2 border-gray-300 fixed bottom-0 w-full py-2 ">
                        <div>
                            {totalpedidoComponente}

                        </div>

                        <div className="flex items-center justify-between w-80 mx-auto mt-2 lg:hidden">
                            <p className="text-[#757575] text-md mr-2">Total:</p>
                            <h2 className="text-[#012a45] text-lg font-semibold">
                                $ {totalpedido?.toLocaleString('es-MX', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                            </h2>
                        </div>
                        <div className='flex justify-center mt-2'>

                            <button
                                onClick={handlePayment}
                                name='btn-handlePayment2'
                                id='btn-handlePayment21'
                                disabled={!formularioCompletado} // Aquí cambias la lógica para deshabilitar el botón
                                className={`py-2 px-10 lg:px-20 lg:py-3 lg:text-xl text-black uppercase rounded font-bold font-roboto transition-colors duration-300 ${formularioCompletado ? 'bg-[#e9bf56] hover:bg-[#1e3d8b] hover:text-white' : 'bg-gray-300 text-gray-500 cursor-not-allowed'
                                    }`}
                            >
                                Pagar
                            </button>

                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}
export default PaginaMetodoPAgo;
