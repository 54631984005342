import React, { useState, useEffect } from 'react';
import imgTarjetas from '../../assets/Tarjetas3.webp';
import imgOxxo from '../../assets/imgoxxopay.png';
import { useNavigate } from 'react-router-dom';
// import TarjetaGuardada from './TarjetaGuardada';

function Metodopago({ onMetodosleecChange, onTarjetaSeleccionadaChange, totalApagar, otrometodo, cart, enviarPago }) {

    const token = localStorage.getItem('k-9SMD879na89dN87m') ? JSON.parse(localStorage.getItem('k-9SMD879na89dN87m')) : null

    const [metodoSeleccionado, setMetodoSeleccionado] = useState('');
    const [tarjetasGuardadas, setTarjetasGuardadas] = useState([
        { idTarjeta: 1, token: "abc123def456ghi7", numFinales: '1234', tipoTarjeta: 'Crédito', compania: 'visa' },
        { idTarjeta: 2, token: "jkl890mno123pqr4", numFinales: '5678', tipoTarjeta: 'Débito', compania: 'mastercard' },
    ]);

    const navigate = useNavigate();
    useEffect(() => {
        // Aquí puedes hacer solicitudes de datos o inicializaciones
    }, []);

    // useEffect para cambios en metodoSeleccionado
    useEffect(() => {
        if (metodoSeleccionado) {
            const tarjetaSeleccionada = tarjetasGuardadas.find(tarjeta => tarjeta.token.toLowerCase() === metodoSeleccionado.toLowerCase());

            // Envia metodosleec al elemento padre
            onMetodosleecChange(metodoSeleccionado);

            // Comprueba si tarjetaSeleccionada existe antes de enviarla al elemento padre
            if (tarjetaSeleccionada) {
                // console.log('Tipo de tarjeta:', tarjetaSeleccionada.tipoTarjeta);
                // console.log('Token:', tarjetaSeleccionada.token);
                onTarjetaSeleccionadaChange(tarjetaSeleccionada);
            }
        }
    }, [metodoSeleccionado, tarjetasGuardadas, onMetodosleecChange, onTarjetaSeleccionadaChange]);




    const seleccionarMetodo = (metodo) => {
        setMetodoSeleccionado(metodo);
    };



    return (
        <div className='mb-14'>
            <div className=' p-3 mx-5 borderinput p-2 rounded-md mt-2 pb-5 '>

                <div className='mt-5 flex justify-left items-center p-3'>
                    <p className='text-xs text-[#030303] font-semibold mr-5' >Tarjeta de crédito ó débito</p>
                    <img width="300" height="200"
                        src={imgTarjetas} alt="Métodos de pago" className='w-28 h-auto cursor-pointer' />
                </div>

                <div className='mt-1'>

                    <label htmlFor="credito" className={`transition flex cursor-pointer hover:bg-[#1a2869] hover:text-white items-center ${metodoSeleccionado === "credito" ? 'bg-[#1a2869] text-white rounded mt-2 h-14 cursor-pointer'
                        : 'text-[#1a2869]  rounded mt-2  h-14 borde-personalizado'}`} >
                        <input type="radio" id="credito" name="metodoPago" value="credito" checked={metodoSeleccionado === "credito"} onChange={() => seleccionarMetodo("credito")} className="sr-only"
                        />
                        <label htmlFor="credito" className='ml-5  cursor-pointer'>
                            <i className="fas fa-credit-card mr-2"></i>
                            Tarjeta de Crédito a Meses
                        </label>
                    </label>
                    {!otrometodo && (
                        <label htmlFor="creditocontado" className={`transition flex cursor-pointer hover:bg-[#1a2869] hover:text-white items-center ${metodoSeleccionado === "creditocontado" ? 'bg-[#1a2869] text-white rounded mt-2 h-14 cursor-pointer'
                            : 'text-[#1a2869] rounded mt-2 h-14 borde-personalizado'}`}>
                            <input
                                type="radio"
                                id="creditocontado"
                                name="metodoPago"
                                value="creditocontado"
                                checked={metodoSeleccionado === "creditocontado"}
                                onChange={() => seleccionarMetodo("creditocontado")}
                                className="sr-only"
                            />
                            <label htmlFor="creditocontado" className='ml-5 cursor-pointer'>
                                <i className="fas fa-credit-card mr-2"></i>
                                Tarjeta de Crédito Contado
                            </label>
                        </label>
                    )}


                    <label htmlFor="debito" className={`transition flex cursor-pointer hover:bg-[#1a2869] hover:text-white items-center ${metodoSeleccionado === "debito" ? 'bg-[#1a2869] text-white rounded mt-2 h-14 '
                        : 'text-[#1a2869]  rounded mt-2  h-14 borde-personalizado'}`} >
                        <input className="sr-only" type="radio" id="debito" name="metodoPago" value="debito" checked={metodoSeleccionado === "debito"} onChange={() => seleccionarMetodo("debito")} />
                        <label htmlFor="debito" className='ml-5  cursor-pointer'>
                            <i className="fas fa-credit-card mr-2"></i>
                            Tarjeta de Débito
                        </label>
                    </label>
                </div>



                <div>
                    <div className='mt-5 flex justify-left items-center p-3'>
                        <p className='text-xs text-[#030303] font-semibold mr-5' >Otros métodos de pago</p>
                    </div>

                    {/* Condición para no mostrar la opción de Oxxo cuando el token es Tienda y el total a pagar es menor o igual a 10000 */}
                    {!(token && token['$r07sad'] === 'Tienda') && totalApagar <= 10000 && (
                        <label htmlFor="oxxo" className={`transition flex cursor-pointer items-center hover:bg-[#1a2869] hover:text-white ${metodoSeleccionado === "oxxo" ? 'bg-[#1a2869] text-white rounded  h-14'
                            : 'text-[#1a2869]  rounded mt-1 h-14 borde-personalizado'}`}>
                            <input
                                type="radio"
                                id="oxxo"
                                name="metodoPago"
                                value="oxxo"
                                checked={metodoSeleccionado === "oxxo"}
                                className="sr-only"
                                onChange={() => seleccionarMetodo("oxxo")}
                            />
                            <label htmlFor="oxxo" className='transition flex items-center ml-5  cursor-pointer'>
                                <img width="300" height="200"
                                    src={imgOxxo} alt="Oxxo" className="w-5 h-4 mr-2" /> {/* Ajusta el tamaño según sea necesario */}
                                Depósito en Oxxo
                            </label>
                        </label>
                    )}
                    {/* Btn Mercado Pago */}
                    {true && 
                        <label htmlFor="mercadoPago" className={`transition flex cursor-pointer items-center hover:bg-[#1a2869] hover:text-white ${metodoSeleccionado === "mercadoPago" ? 'bg-[#1a2869] text-white rounded  h-14'
                            : 'text-[#1a2869]  rounded mt-1 h-14 borde-personalizado'}`}>
                            <input
                                type="button"
                                id="mercadoPago"
                                name="metodoPago"
                                value="mercadoPago"
                                className="sr-only"
                                onClick={() => { seleccionarMetodo("mercadoPago"); enviarPago("mercadoPago") }}
                            />

                            <label htmlFor="mercadoPago" className='transition flex items-center ml-5 cursor-pointer'>
                                <img width="400" height="300"
                                    src="https://firebasestorage.googleapis.com/v0/b/dotkomma.appspot.com/o/hantec_ecommerce%2Fextra_documents%2Flogo-mp.png?alt=media"
                                    alt="mercado-pago" className="w-5 h-4 mr-2" /> 
                                Pagar con Mercado Pago
                            </label>
                        </label>
                    }

                    {/* <div className="flex flex-col items-center w-full mt-5  borde-personalizado pb-5">
                        <button
                            className="flex items-center justify-center bg-[#009EE3] text-white rounded-md w-full max-w-[300px] py-2 px-4 hover:opacity-90 shadow-lg"
                            style={{ border: "none" }}
                            onClick={() => { seleccionarMetodo("mercadoPago"); enviarPago("mercadoPago") }}
                        >
                            <img
                                src="https://firebasestorage.googleapis.com/v0/b/dotkomma.appspot.com/o/hantec_ecommerce%2Fextra_documents%2Flogo-mp.png?alt=media"
                                alt="Mercado Pago Logo"
                                className="w-6 h-6 mr-2"
                            />
                            Pagar con Mercado Pago
                        </button>
                        <span className="text-gray-500 text-xs mt-2">Paga de forma segura</span>
                    </div> */}
                    {/* <label htmlFor="mercadoPago" className='flex'>
                    </label> */}

                    {/* Condición para no mostrar la opción de transferencia cuando el token es Tienda */}
                    {!(token && token['$r07sad'] === 'Tienda') && (

                        <label htmlFor="transferencia" className={`transition flex items-center hover:bg-[#1a2869] hover:text-white ${metodoSeleccionado === "transferencia" ? 'bg-[#1a2869] text-white  mt-2 h-14 cursor-pointer'
                            : 'text-[#1a2869]   mt-2  h-14 borde-personalizado cursor-pointer'}`}>

                            <label htmlFor="transferencia" className='flex items-center ml-5  cursor-pointer'>
                                {/* Suponiendo que imgOxxo es el path de tu imagen */}
                                <input
                                    type="radio"
                                    id="transferencia"
                                    name="transferencia"
                                    value="transferencia"
                                    checked={metodoSeleccionado === "transferencia"}
                                    className="sr-only"
                                    onChange={() => seleccionarMetodo("transferencia")}
                                />

                                <i className="fas fa-university mr-2"></i>
                                Pago por Transferencia
                            </label>
                        </label>

)}
                    {/* <PaymentMP products={cart}/> */}
                </div>


                <div className='text-center px-1 mt-2'>
                    <p className='text-xs text-[#030303]'>
                        Luego de seleccionar un método se pago, serás redirigido a para completar tu compra de forma segura.
                    </p>
                </div>
            </div>
        </div>
    );
}

export default Metodopago;
